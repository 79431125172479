import { CategoryTileProps } from "../../../components/CategoryTile/CategoryTile";
import { useElementContext, useProductContext } from "../../../contexts";

export interface ProductCategoriesProps {
  customStyleClasses?: string;
  cardStyle: "Text over image" | "Text below image";
  itemPreRowDesktop: number;
  itemPreRowTablet: number;
  itemPreRowMobile: number;
  textOverlayColor: string;
  textOverlayOpacity: number;
  textColor: string;
  title?: string;
  categoryMetaData: {
    [key: string]: {
      categoryLogo?: string;
      categoryName?: string;
      categoryDescription?: string;
      linkedPimCategory: string;
    };
  };
}

const ProductCategories = ({ categoryMetaData, ...rest }: ProductCategoriesProps) => {
  const {
    CommonModule: { CategoryTile },
  } = useElementContext();
  const { product } = useProductContext();

  const categories: CategoryTileProps["categories"] = (product?.categories || [])?.map((category) => {
    const cmsCategory = categoryMetaData[category.urlTitle] || {};
    return {
      categoryName: cmsCategory.categoryName || category.categoryName,
      categoryDescription: cmsCategory.categoryDescription || category.categoryDescription,
      categoryLogo: cmsCategory.categoryLogo,
      linkedPimCategory: cmsCategory.linkedPimCategory || category.urlTitle,
    };
  });

  return <CategoryTile {...rest} categories={categories} />;
};

export { ProductCategories };
